@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

/* Ensure the sections span full width */
html, body {
    margin: 0;
    padding: 0;
    width: 100%;
}

p {
  font-size: 1rem;
  line-height: 1.75
}
  
.HomePage {
    font-family: 'Montserrat', sans-serif;
}
  
/* Common styles for all sections */
.HomePage-section {
    width: 100%; /* Ensure the section spans the full width */
    padding: 4rem 2rem;
    text-align: center;
    color: white;
    box-sizing: border-box; /* Include padding in width calculations */
}

.subtitle {
    font-size: 1.2rem;
    line-height: 1.75;
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
}
  
/* Title Section */
.title-section {
  background-color: #192e58;
  color: white;
  text-align: center;
  padding: 6rem 2rem;
  position: relative; /* Needed for absolute positioning of the arrow */
}


  .title-section::after {
    content: "";
    position: absolute;
    bottom: -30px; /* Adjust as needed */
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 50px solid transparent;  /* Adjust width */
    border-right: 50px solid transparent; /* Adjust width */
    border-top: 40px solid #192e58; /* Adjust height and color to match background */
    z-index: 1;
}
  
  .title-logo {
    width: 300px; /* Adjust the logo size */
    margin-bottom: 1.5rem;
  }
  
  .title-section h1 {
    font-size: 2rem;
    margin: 0 auto;
    font-weight: 600;
    color: white;
    max-width: 600px;
  }

  .title-section i {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500; /* Medium */
    font-style: italic;
  }
  
  .title-section p {
    padding-top: 20px;
    max-width: 750px;
    margin: 0 auto;
  }


/* What We Do Section */
.what-we-do-section {
  background-color: white;
  color: #192e58;
  text-align: center;
  padding: 4rem 2rem;
  position: relative;
  max-width: 1200px;
  margin: 0 auto; /* This centers the element */
}

.what-we-do-section::after {
  content: "";
  position: absolute;
  bottom: -30px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
  border-top: 40px solid white; /* Matches what-we-do-section background */
  z-index: 1;
}

  .what-we-do-section h2 {
    color: #192e58;
    font-size: 2rem;
  }
  
  .what-we-do-content {
    padding-top: 4rem;
    padding-bottom: 5rem;
    display: flex;
    justify-content: center; /* Centers horizontally */
    align-items: center; /* Centers vertically */
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto; /* Centers horizontally within the parent/container */
    height: 100%; /* Ensure it fills the parent's height */
}
  
  .what-we-do-item {
    flex: 1;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem;
    max-width: 300px;
    margin: auto;
    min-height: 300px; /* Ensure equal height across items */
  }
  
  .circle-title {
    display: flex;
    align-items: center; /* Center-align the circle and title */
    gap: 1rem;
    margin-bottom: 1rem;
  }
  
  .circle {
    width: 50px;
    height: 50px;
    border: 3px solid #368caa;
    color: #368caa;
    font-size: 1.5rem;
    font-weight: bold;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
  }
  
  .what-we-do-item h3 {
    font-size: 1.25rem;
    margin: 0;
  }

  .three-distinct {
    padding-top: 20px;
  }

  .three-distinct p {
    font-weight: bold;
  }
  
  .what-we-do-item p {
    margin: 1rem 0;
    flex-grow: 1; /* Allows the paragraph to take up remaining space */
  }

  @media (max-width: 1121px) {
    .what-we-do-content {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  
    .what-we-do-item {
      max-width: 100%; /* Ensures full width */
      align-items: center;
      text-align: center; /* Centers text for mobile view */
    }
  
    .MuiDivider-root {
      width: 80%;
      height: 2px; /* Horizontal divider instead of vertical */
      margin: 1rem 0;
    }
  }
  
  
  button.MuiButton-root {
    background-color: #192e58;
    color: white;
    text-transform: uppercase;
    font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-weight: bold;
    margin-top: auto; /* Push buttons to the bottom of the container */
    align-self: center; /* Center-align buttons */
  }
  
  button.MuiButton-root:hover {
    background-color: #1a3a6b;
  }  
  
  .mission-section {
    background-color: #368caa;
    color: #192e58;
    /* font-weight: bold; */
    text-align: center; /* Centers all text within the section */
    padding: 4rem 2rem; /* Add some padding for spacing */
  }
  
  .mission-section h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
    color: #192e58;
    text-align: center;
  }
  
  .mission-section p {
    color: white;
    max-width: 800px; /* Constrain paragraph width */
    margin: 0 auto; /* Center paragraph within its container */
  }
  

  