.footer-section {
  background-color: #192e58;
  color: white;
  padding: 2rem 1rem;
  font-size: 1rem;
  text-align: center; /* Center text and inline elements */
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  align-items: center; /* Center elements horizontally */
  justify-content: center; /* Center elements vertically */
}

.footer-section h2 {
  max-width: 400px;
  font-weight: 600;
  font-size: 1.5rem; /* Default font size */
}

/* Footer Logo */
.footer-logo {
  width: 150px; /* Default logo size */
  margin-bottom: 1.5rem;
  transition: width 0.3s ease-in-out;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .footer-section {
      padding: 1.5rem 1rem;
  }

  .footer-section h2 {
      font-size: 1.25rem; /* Slightly smaller font size for better fit */
      max-width: 90%; /* Allow better scaling */
  }

  .footer-logo {
      width: 120px; /* Smaller logo for mobile */
  }
}

/* Extra Small Screens (Phones) */
@media (max-width: 480px) {
  .footer-section {
      padding: 1rem;
  }

  .footer-section h2 {
      font-size: 1.1rem; /* Reduce font size further */
  }

  .footer-logo {
      width: 100px; /* Further reduce logo size */
  }
}