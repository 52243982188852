.header-section {
    padding: 6rem 4rem;
    padding-bottom: 5rem;
    text-align: left;
    color: #192e58;
    position: relative;
}

.header-section h1 {
    text-align: center !important;
}

.header-section h2 {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    text-align: left !important;
}

.header-section p {
    max-width: 1200px;
    margin: 0 auto;
    text-align: left !important;
}

.header-section li {
    list-style-type: lower-alpha;
    max-width: 1000px;
    margin: 0 auto;
    text-align: left;
}