.contact-us-section {
    padding: 6rem 4rem;
    padding-bottom: 5rem;
    text-align: center;
    margin-bottom: 3rem;
    background-color: #192e58;
    color: white;
    position: relative;
}

.contact-us-section::after {
    content: "";
    position: absolute;
    bottom: -30px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 50px solid transparent;
    border-right: 50px solid transparent;
    border-top: 40px solid #192e58; /* Matches what-we-do-section background */
    z-index: 1;
  }

.contact-us-section h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
}

.contact-us-section p {
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
}

.meeting-section {
    width: 100%; /* Ensure the section spans the full width */
    padding: 4rem 2rem;
    text-align: center;
    color: #192e58;
    box-sizing: border-box; /* Include padding in width calculations */
}