.Navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #368CAA;
  padding: 1rem 2rem;
  color: white;
  position: relative;
}

/* Align Logo to the Left */
.Navbar-logo {
  display: flex;
  align-items: center;
}

/* Logo Image */
.Navbar-logo-img {
  height: 60px;
  object-fit: contain;
}

/* Desktop Navigation Links */
.Navbar-links {
  list-style: none;
  display: flex;
  gap: 2rem;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  font-weight: bold;
  margin-left: auto; /* Push links to the right */
}

/* Style Links */
.Navbar-links a {
  color: white;
  text-decoration: none;
  transition: color 0.3s ease;
  padding: 0.5rem 0;
  position: relative;
}

.Navbar-links a:hover {
  color: #192e58;
}

.Navbar-links a::after {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  background: #192e58;
  transition: width 0.3s ease;
  margin: auto;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.Navbar-links a:hover::after {
  width: 100%;
}

.Navbar-links a.active {
  color: white;
}

.Navbar-links a.active::after {
  width: 100%;
}

/* Hide Hamburger Menu by Default */
.Navbar-menu-icon {
  display: none;
}

/* Mobile Styles */
@media (max-width: 768px) {
  /* Hide Desktop Links */
  .Navbar-links {
    display: none;
  }

  /* Show Hamburger Menu on Mobile */
  .Navbar-menu-icon {
    display: block;
    color: white;
    position: absolute;
    right: 1rem;
  }
}

/* Drawer Styles */
.Navbar-drawer {
  width: 250px;
  padding: 1rem;
  background-color: #368CAA;
  height: 100%;
}

.Navbar-drawer-close {
  display: flex;
  justify-content: flex-end;
}

.Navbar-drawer .MuiListItem-root {
  text-transform: uppercase;
  color: white;
}

.Navbar-drawer .MuiListItem-root:hover {
  background-color: rgba(255, 255, 255, 0.1);
}