.header-section {
    padding: 6rem 4rem;
    padding-bottom: 5rem;
    text-align: center;
    color: #192e58;
    position: relative;
}

.header-section::after {
  content: "";
  position: absolute;
  bottom: -30px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
  border-top: 40px solid white; /* Matches what-we-do-section background */
  z-index: 1;
}

.header-section h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
}

.header-section .tagline {
    font-size: 1.25rem;
    margin-bottom: 1rem;
    color: #192e58;
    text-align: center !important;
    font-weight: bold;
    max-width: 1000px;
    margin: 0 auto;
}

.header-section .benefit-description {
    padding-top: 75px;
    color: #4f4f4f;
    max-width: 850px;
    margin: 0 auto;
}

.benefits {
  padding-top: 40px;
}

.header-section .end-tagline {
    text-align: center !important;
    color: #4f4f4f
}

.header-section .closing-section {
    margin-top: 40px
}

.header-section p {
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
}

.case-study-button {
    /* margin-top: 1rem; */
    color: white;
    font-weight: bold;
    cursor: pointer;
    text-align: 'left'
}

  /* Key Features Section */
  .key-features-section {
    color: #192e58;
    padding: 4rem 2rem;
    width: 100%;
    padding: 4rem 2rem;
    box-sizing: border-box;
    text-align: center;
  }
  
  .key-features-section h2 {
    color: #192e58;
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  
  /* Grid Layout for Features */
  .key-features-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;
    text-align: left !important;
  }
  
  /* Feature Item Styles */
  .key-feature-item {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    background-color: white;
    border-radius: 8px;
    box-shadow: 2px 4px 6px 2px rgba(0, 0, 0, 0.1);
    margin-top: 1rem;
    min-height: 150px
  }
  
  .feature-icon {
    color: #368caa;
    font-size: 8rem; /* Significantly larger size */
    width: auto; /* Reset any inherited width constraints */
    height: auto; /* Reset any inherited height constraints */
}
  
  .key-feature-item h3 {
    font-size: 1.25rem;
    font-weight: bold;
    margin: 0 0 0.5rem;
  }
  
  .key-feature-item p {
    margin: 0;
  }
  
  .plan-protection-section {
    display: flex;
    flex-direction: column; /* Stack children vertically */
    justify-content: center; /* Centers items vertically */
    align-items: center; /* Centers items horizontally */
    background-color: rgba(54, 140, 170);
    color: #192e58;
    padding: 4rem 2rem;
    text-align: center; /* Centers text content */
}


  
  .plan-protection-section h2 {
    color: #192e58;
    font-size: 2rem;
    margin-bottom: 1rem;
    text-align: center;
  }
  
  .protection-intro {
    font-size: 1.2rem;
    line-height: 1.75;
    max-width: 1000px;
    margin: 0 auto 3rem;
    text-align: center;
  }
  
  .protection-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 2rem;
    max-width: 1000px;
    margin: 0 auto;
  }
  
  .protection-item {
    display: flex;
    flex-direction: column; /* Stack icon and content vertically */
    align-items: center; /* Center align all content */
    padding: 2rem;
    background-color: white;
    /* border: 1px solid #192e58; */
    border-radius: 12px; /* Slightly more rounded corners */
    box-shadow: 2px 4px 6px 2px rgba(0, 0, 0, 0.1); /* Softer shadow */
  }
  
  .protection-icon {
    color: #368caa;
    font-size: 3rem;
    flex-shrink: 0;
  }
  
  .protection-item h3 {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
    text-align: center;
  }
  
  .plan-protection-section p {
    color: white;
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
  }

  .accel-item {
    display: grid;
    grid-template-rows: auto 1fr; /* Ensures the paragraph aligns at the top */
    text-align: left;
    align-items: start;
    justify-items: center; /* Centers the image horizontally */
    min-height: 250px; /* Ensures uniform height */
    padding: 1rem;
    max-width: 300px;
    margin: auto;
}

.accel-item p {
    font-size: 1.25rem;
    align-self: start; /* Ensures paragraph starts at the top */
    text-align: center; /* Centers text inside the container */
    margin-top: 50px; /* Removes extra spacing */
}


  .accel-item-logo-img {
    flex: 1;
    display: flex;
    max-width: 150px;
    max-height: 100px;
  }

  .reimbursement {
    padding-bottom: 40px;
  }

  .reimbursement-text {
    margin-top: 10px !important
  }
  