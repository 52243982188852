/* About Us Section */
.about-us-section {
    padding: 6rem 4rem;
    padding-bottom: 5rem;
    text-align: center;
    margin-bottom: 3rem;
    background-color: #192e58;
    color: white;
    position: relative;
}

.about-us-section::after {
    content: "";
    position: absolute;
    bottom: -30px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 50px solid transparent;
    border-right: 50px solid transparent;
    border-top: 40px solid #192e58; /* Matches section background */
    z-index: 1;
}

.about-us-section h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
}

.about-us-section p {
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
    font-size: 1.1rem;
}

/* Our Team Section */
.our-team-section {
    color: #192e58;
    text-align: center;
    padding: 4rem 2rem;
}

.our-team-section h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
}

.our-team-section h2 {
    font-size: 1.25rem;
    margin-bottom: 0rem;
}

.team-title {
    margin-top: 0.25rem;
    font-size: 1rem; /* Smaller font for the title */
    color: #368CAA;
    text-align: center !important;
}

.our-team-section p {
    max-width: 1200px;
    margin: 0 auto;
    padding-bottom: 2rem;
    text-align: center;
    font-size: 1.1rem;
}

/* Team Grid - Responsive */
.team-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 4 columns for desktop */
    gap: 2rem; /* Space between grid items */
    margin: 2rem auto;
    max-width: 1000px; /* Constrain grid width */
    padding: 0 2rem; /* Moderate padding on sides */
}

/* Team Grid - Responsive */
@media (max-width: 1024px) {
    .team-grid {
        grid-template-columns: repeat(2, 1fr); /* 2 columns for tablets */
        gap: 1.5rem;
    }
}

@media (max-width: 768px) {
    .about-us-section {
        padding: 4rem 2rem;
    }

    .about-us-section h1,
    .our-team-section h1 {
        font-size: 1.75rem;
    }

    .about-us-section p,
    .our-team-section p {
        font-size: 1rem;
    }

    .team-grid {
        grid-template-columns: repeat(1, 1fr); /* 1 column for mobile */
        gap: 1rem;
    }

    .team-title {
        font-size: 0.9rem;
    }
}

/* Team Member Card */
.team-member {
    text-align: center;
}

/* Image Wrapper */
.image-wrapper {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto;
    border: 1px solid #E0E0E0; 
    position: relative;
    transition: transform 0.3s;
}

.image-wrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s;
}

.image-wrapper::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(25, 46, 88, 0.5); /* Transparent shade of #192e58 */
    opacity: 0;
    transition: opacity 0.3s;
    border-radius: 50%; /* Match the circular image */
}

.image-wrapper:hover {
    transform: scale(1.05); /* Slightly enlarge the image on hover */
}

.image-wrapper:hover::after {
    opacity: 1; /* Show the overlay */
}

/* Team Name */
.team-name {
    font-size: 1.2rem;
    text-transform: uppercase;
    font-weight: bold;
    color: #192e58;
    max-width: 250px;
    text-align: center;
    margin: 0 auto; /* Centers the element */
    display: block; /* Ensures margin auto works */
    padding-top: 20px;
}

.team-member p {
    margin-top: 0.5rem;
    font-weight: 500;
}

/* Make Image & Name Adjust for Smaller Screens */
@media (max-width: 480px) {
    .image-wrapper {
        width: 150px;
        height: 150px;
    }

    .team-name {
        font-size: 1rem;
        padding-top: 10px;
    }

    .team-member p {
        font-size: 0.9rem;
    }
}