.aegle-container {
    position: relative;
    width: 800px; /* Increased size */
    height: 800px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.outer-circle {
    position: relative;
    width: 375px; /* Adjust for size */
    height: 375px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: conic-gradient(
        #a6a6a6 0% 10%,
        #1e2c55 10% 30%,
        #cecece 30% 50%,
        #54565b 50% 70%,
        #8196c6 70% 90%,
        #a6a6a6 90% 100% 
    );
}

.aegle-accel-circle {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    position: absolute;
}

.graphic-circle {
    width: 160px;
    height: 160px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* font-weight: bold; */
    position: absolute;
    transition: transform 0.4s ease-in-out;
}

.graphic-circle p {
    font-size: 1rem;
    font-weight: bold;
    max-width: 140px;
}

.graphic-circle:hover {
    transform: scale(1.2);
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}

.center {
    width: 250px;
    height: 250px;
    background-color: #368caa;
    color: white;
    font-size: 42px;
    position: relative;
    border: 25px solid white;
}

/* Further move the circles outward */
.spoke {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

/* Apply background color directly to the circle inside spoke1 */
.spoke1 .graphic-circle {
    background-color: #a6a6a6 !important;
}
.spoke2 .graphic-circle {
    background-color: #1e2c55 !important;
}
.spoke3 .graphic-circle {
    background-color: #cecece !important;
    color: #4f4f4f !important
}
.spoke3 .graphic-circle p {
    color: #4f4f4f !important 
}
.spoke4 .graphic-circle {
    background-color: #54565b !important;
}
.spoke5 .graphic-circle {
    background-color: #8196c6 !important;
}


/* Spoke positioning - moved farther out */
.spoke1 { background-color: #a6a6a6; top: -30%; left: 50%; transform: translate(-50%, 0); }  
.spoke2 { top: 20%; right: -30%; transform: translate(0, -50%); }  
.spoke3 { bottom: -20%; right: -5%; transform: translate(0, 50%); }  
.spoke4 { bottom: -20%; left: -5%; transform: translate(0, 50%); }  
.spoke5 { top: 20%; left: -30%; transform: translate(0, -50%); }

/* Hover Box Styling */
.hover-box {
    position: absolute;
    background-color: white;
    color: black;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    width: 500px;
    text-align: left;
    font-size: 14px;
    opacity: 0;
    transform: translate(-50%, -50%);
    transition: opacity 0.3s ease-in-out;
    pointer-events: none;
    z-index: 10;
    padding-bottom: 50px;
    padding-top: 50px;
    padding-left: 25px;
    padding-right: 25px;
}

/* Increase z-index when hovered */
.spoke:hover {
    z-index: 100; /* Ensure hovered spoke is on top */
}

/* Ensure hover box itself is on top */
.spoke .hover-box {
    z-index: 100;
}


/* Show on hover */
.spoke:hover .hover-box {
    opacity: 1;
    z-index: 100;
}

.details-list {
    list-style-type: disc; /* Use disc for standard bullets */
    padding-left: 20px; /* Adds space for bullets */
    margin: 0;
}

.details-item {
    font-size: 1rem;
    display: flex;
    align-items: center; /* Aligns icon and text properly */
    margin-bottom: 40px;
}

.icon-img {
    width: 40px;
    height: 100px;
    object-fit: contain;
    margin-right: 10px; /* Space between icon and text */
    flex-shrink: 0; /* Prevents resizing */
}

.details-text {
    display: flex;
    flex-direction: column; /* Stack bullet points */
    gap: 10px;
}

/* Ensure bullet points appear */
.details-text span {
    display: list-item;
    list-style-type: inherit; /* Uses parent list style */
    margin-left: 15px; /* Adjust for bullet indentation */
}


.icon-img {
    width: 100px;
    height: 70px;
    object-fit: contain;
    margin-right: 10px; /* Space between icon and text */
    flex-shrink: 0; /* Prevents the icon from resizing */
    padding-right: 20px;
    display: block;
}

.details-item span {
    display: flex;
    align-items: center; /* Aligns text with icon */
}


/* Remove margin from the last item */
.details-item:last-child {
    margin-bottom: 0;
}

li::marker {
    color: #368caa;
  }

/* Adjust hover box position relative to the circle */
.spoke1 .hover-box {
    top: 100%; /* Moves the hover box below the spoke */
    left: 50%; /* Centers it relative to the spoke */
    transform: translate(-50%, 10px); /* Moves it slightly downward */
}
.spoke2 .hover-box { top: 50%; right: 120px; transform: translate(0, -50%); }
.spoke3 .hover-box { top: 50%; right: 120px; transform: translate(0, -50%); }
.spoke4 .hover-box { top: 50%; left: 120px; transform: translate(0, -50%); }
.spoke5 .hover-box { top: 50%; left: 120px; transform: translate(0, -50%); }

