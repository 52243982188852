/* Risk Financing Title Section */
.risk-financing-title-section {
    padding-top: 2rem;
    padding-bottom: 5rem;
    text-align: center;
    background-color: #192e58;
    color: white;
}

.risk-financing-title-section h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
}